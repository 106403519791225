@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');

/* Root color variables */
:root {
  --Transparent: transparent;
  --Current: currentColor;
  --Gray: #757575;
  --Blue: #34428C;
  --DarkBlue: #0f1740;
  --DarkGrey: #343A40;
  --CharcoalBlue: #161932;
  --CharcoalBlack: #2E2A38;
  --transparentGlass: #302b3a;
  --graphBg: #f3f3ff;
  
  --Orange: #f68528;
  --Red: #FF0000;
  --SeaBlue: #3aabfe;
  --Black: #000000;
  --Pink: #FF007F;
  --Yellow: #ffd200;
  --Magenta: #FF00FF;
  --LightGray: #f3f0f0;
  --DarkGray: #343A40;
  --DisableColor: #f9f9f9;
  
  
  /* NEW */
  --White: #FFFFFF;
  --Green: #099652;
  --BgEbony: #110c1d;
  --grayText: #7a7a7b;
  --LightGreen: #7ed957;
  --borderColor: #b2a9b0;
  --primaryColor: #004aad;
  --tableHeaderColor: #f3f3ff;
  --primaryButtonColor: #004aad;
  --fontFamily: "DM Sans", sans-serif !important;
  /* Default font family */
}

/* Tailwind directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  p {
    @apply description !important;
  }

  dt, span {
    @apply text-base md:text-lg;
  }

  a {
    @apply no-underline hover:underline text-base;
  }
}

@layer components {
  .fontDM {
    @apply font-dmSans !important;
  }

  .fontMerriweather {
    @apply font-merriWeather !important;
  }

  .pressStart {
    @apply font-pressStart !important;
  }

  .primaryButton {
    @apply bg-primaryColor h-11 text-White font-bold active:opacity-80 !important;
  }

  /* Large Title Variant */
  .titleLarge {
    @apply font-merriWeather text-title30 lg:text-title36 !important;
  }

  .titleLargeDM {
    @apply font-dmSans text-title30 lg:text-title36 !important;
  }

  /* Medium Title Variant */
  .titleMedium {
    @apply font-merriWeather text-title28 lg:text-title30 !important;
  }

  .titleMediumDM {
    @apply font-dmSans text-title28 lg:text-title30 !important;
  }

  /* Small Title Variant */
  .titleSmall {
    @apply font-merriWeather text-title26 lg:text-title28 !important;
  }

  .titleSmallDM {
    @apply font-dmSans text-title26 lg:text-title28 !important;
  }

  .description {
    @apply font-dmSans text-description lg:text-descriptionLarge !important;
  }
  .para{
    @apply text-base md:text-lg;
  }

  .progressButton {
    @apply text-primaryColor bg-graphBg font-semibold hover:bg-graphBg hover:text-primaryColor !important;
  }

  .getStartedButton {
    @apply bg-red-100 text-red-500 font-semibold hover:bg-red-100 hover:text-red-500 !important;
  }

  .completedButton {
    @apply bg-green-100 text-Green font-semibold hover:bg-green-100 hover:text-Green !important;
  }

  .commonButton {
    @apply bg-primaryColor hover:bg-transparent border-2 hover:border-primaryColor hover:text-black text-white w-10rem h-2.5rem border-primaryColor !important;
  }

  .commonBlackButton {
    @apply bg-black hover:bg-transparent hover:border-black hover:text-black text-white w-10rem h-2.5rem border-black !important;
  }

  .outlineButton {
    @apply bg-transparent hover:bg-primaryColor text-white hover:text-white w-10rem h-2.5rem border-primaryColor !important;
  }

  .grayButton {
    @apply bg-Gray text-white w-10rem h-2.5rem border-primaryColor !important;
  }

  .outlineGrayButton {
    @apply border-primaryColor bg-CharcoalBlack text-black;
  }

  .commonInput {
    @apply h-11 border border-Gray placeholder:text-gray-400 outline-none text-lg;
  }

  .whiteSelect span.ant-select-selection-item {
    @apply text-white;
  }

  .commonInput input::placeholder {
    @apply text-gray-400;
  }

  .buttonMinWidth {
    @apply min-w-36;
  }

  .ant-select-selector {
    @apply bg-transparent !important;
  }

  .darkPlaceholderSelect.ant-select-selection-placeholder {
    @apply text-gray-400;
  }
  .callButton span {
    @apply description !important;
  }
  .termsButton span {
    @apply description underline inline !important;
  }
}

/* Global styles */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: var(--fontFamily) !important;
}

body {
  @apply font-merriWeather description !important;
  /* Set default to DM Sans */
}

::-webkit-scrollbar {
  width: 5px;
  background: var(--White);
  border-radius: 50px;
}

::-webkit-scrollbar-thumb {
  background: var(--primaryColor);
  border-radius: 50px;
}

.table_scroll::-webkit-scrollbar {
  width: 5px;
}

.businessList::-webkit-scrollbar {
  height: 5px;
}

.thinScroller::-webkit-scrollbar {
  height: 5px;
}

.businessList::-webkit-scrollbar-thumb {
  background: var(--Gray);
}

.ant-avatar>img {
  @apply object-contain;
}

.highcharts-title{
  @apply font-merriWeather text-title26 lg:text-title28 !important;
}

.ant-slider .ant-slider-handle::after {
  border-radius: 50px;
  height: 15px !important;
  width: 15px !important;
  box-shadow: none;
  background: var(--White);
  border: 1px solid var(--Gray);
}

.ant-slider-handle:hover::after {
  box-shadow: none;
}

.ant-slider .ant-slider-handle:hover::after {
  box-shadow: none;
}

.ant-slider .ant-slider-handle:focus::after {
  box-shadow: none;
}

.ant-slider .ant-slider-rail {
  height: 10px;
  border-radius: 50px;
}

.ant-slider .ant-slider-track {
  background: var(--Gray);
  height: 10px;
  border-radius: 50px;
}

.ant-slider:hover .ant-slider-track {
  background: var(--Gray);
}

.ant-select-selection-placeholder {
  color: var(--Gray) !important;
}

.ant-select-item-empty {
  color: var(--Black) !important;
}

.ant-select-single.ant-select-open .ant-select-selection-item {
  color: var(--Gray) !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
  height: 25px !important;
  width: 25px !important;
}

.lineChart {
  height: 20rem;
}

.ant-picker-calendar .ant-picker-cell-in-view.ant-picker-cell-selected:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
  background-color: var(--primaryColor);
  border-radius: 1rem;
}

.ant-picker-calendar .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-radius: 1rem;
}

.ant-picker-calendar .ant-picker-panel {
  color: var(--White) !important;
  background-color: var(--Black);
}

.ant-picker-calendar {
  color: var(--White) !important;
  background-color: var(--Black);
}

.ant-picker-calendar .ant-picker-cell-in-view {
  color: var(--White) !important;
}

.rule_select::placeholder {
  color: var(--Black) !important;
}

.ant-drawer .ant-drawer-content {
  background: var(--Black) !important;
}

.ant-table-content::-webkit-scrollbar {
  height: 5px;
}

.ant-input-outlined.ant-otp-input {
  background: transparent !important;
  width: 4rem;
  height: 4rem;
  font-size: 2rem;
}

.ant-otp {
  column-gap: 26px;
}

.ant-table-cell {
  @apply text-black text-para !important;
}

.ant-checkbox-disabled+span {
  @apply text-primaryColor font-semibold select-none;
}

.ant-checkbox-disabled .ant-checkbox-inner:after {
  @apply border-White h-[25px] w-[25px];
}

.build_strategy_modal .ant-modal-content {
  background-color: var(--Black) !important;
}

.lightGrayModal .ant-modal-content {
  background-color: var(--LightGray) !important;
}

.darkBgSelect div {
  background: transparent !important;
}

.grayPlaceholderSelect .ant-select-selection-placeholder {
  color: var(--Gray) !important;
}

.ant-select-disabled {
  /* border: 1px solid var(--Gray) !important; */
  background-color: var(--DisableColor) !important;
}

.ant-select-disabled span.ant-select-selection-item {
  color: var(--BgEbony) !important;
}

.ant-checkbox .ant-checkbox-inner {
  width: 25px;
  height: 25px;
}

.ant-radio-wrapper .ant-radio-inner {
  width: 20px;
  height: 20px;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
  border: 1px dashed var(--CharcoalBlue);
  height: 120px;
  width: 120px !important;
}

.avatar-uploader-logo-setup.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
  border: 1px dashed var(--CharcoalBlue);
  height: 50px !important;
  width: 50px !important;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--primaryColor);
  color: var(--White);
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: var(--Green) !important;
  background-color: var(--Green) !important;
}

.stripe_form_input {
  box-shadow: 0px 3px 7px var(--LightGray);
  border-radius: 5px;
  opacity: 1;
  height: 45px;
  font-weight: 600;
  background-color: var(--graphBg);
}

.black_placeholder input::placeholder {
  color: var(--Black) !important;
}

li.ant-pagination-item {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 50% !important;
}

.ant-select-dropdown .ant-select-item {
  font-size: 1rem;
}

.ant-picker .ant-picker-input>input {
  font-size: 1rem;
}

.ant-input-borderless.ant-input-disabled {
  background-color: var(--DisableColor) !important;
}

.ant-input-outlined[disabled] {
  background-color: var(--DisableColor) !important;
}

.ant-popover .ant-popover-inner {
  background-color: var(--DisableColor);
}

.ant-badge .ant-badge-dot {
  box-shadow: none !important;
}
.ant-checkbox .ant-checkbox-inner:after{
  @apply h-3 w-1 top-[45%] border-[3px] !important;
}
.ant-form-item .ant-form-item-label >label::after{
  @apply hidden ;
}